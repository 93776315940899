import React from "react";
import { graphql } from "gatsby";
import { Link } from "gatsby";
import Layout from "~/components/layout";
import { GatsbySeo, LogoJsonLd } from "gatsby-plugin-next-seo";
import Hero from "~/components/Hero";
import TourCarousel from "~/components/TourCarousel";
//import TourList from "~/components/tour-list";
//import TrustpilotReviews from "~/components/TrustpilotReviews";
import ReviewsCarousel from "~/components/ReviewsCarousel";
import { StaticImage } from "gatsby-plugin-image";
// import stars from "~/images/stars-4.5.svg";
// import tustbadge from "~/images/trust-badge.png";
import ArticleCarousel from "~/components/ArticleCarousel";
// import ReviewsSocialProof from "../components/ReviewsSocialProof";
//import open from "~/images/Open-Quote.svg";
//import close from "~/images/End-Quote.svg";
//import Card from "~/components/styled/card";

const IndexPage = ({ data }) => {
	const tours = data.allStrapiTour.edges;
	const flatTours = tours.map(({ node }) => node);

	const first10Tours = flatTours.slice(0, 12);

	const stats = [
		{
			id: 1,
			name: "Immersive bike tours",
			value: "100+ ",
		},
		{ id: 2, name: "Destinations", value: "20+ " },
		{
			id: 3,
			name: "Happy cyclists",
			value: "5000+ ",
		},
	];

	// Luxury Bike Tours
	// const speciality = data.allStrapiSpeciality.edges;
	// const flatToursSpl = speciality.map(({ node }) => node);
	// const luxuryTours = flatToursSpl.find(
	// 	(spl) => spl.slug === "luxury-bike-tours"
	// );

	// Check if luxuryTours is not null and has tours
	// const first10LuxuryTours = luxuryTours ? luxuryTours.tours.slice(0, 12) : [];

	// Multisport tours
	// const mspeciality = data.allStrapiSpeciality.edges;
	// const flatToursMSpl = mspeciality.map(({ node }) => node);
	// const multiTours = flatToursMSpl.find(
	// 	(spl) => spl.slug === "multi-sport-tours"
	// );
	// const first10MultiTours = multiTours ? multiTours.tours.slice(0, 12) : [];

	// Road tours
	// const rspeciality = data.allStrapiSpeciality.edges;
	// const flatToursRSpl = rspeciality.map(({ node }) => node);
	// const roadTours = flatToursRSpl.find((spl) => spl.slug === "road-bike-tours");
	// const first10RoadTours = roadTours ? roadTours.tours.slice(0, 12) : [];

	// Family tours
	// const fspeciality = data.allStrapiSpeciality.edges;
	// const flatToursFSpl = fspeciality.map(({ node }) => node);
	// const familyTours = flatToursFSpl.find((spl) => spl.slug === "family");
	// const first10FamilyTours = familyTours ? familyTours.tours.slice(0, 12) : [];

	const articles = data.allStrapiArticle.edges;
	const allArticles = articles.map(({ node }) => node);
	const first10Articles = allArticles.slice(0, 12);
	// const indiaTours = flatTours.filter(
	// 	//(dest) => dest.destination.name === "India"
	// 	(type) => {
	// 		return type.tourType === "multiday" && type.destination.name === "India";
	// 	}
	// );
	//const asiaTours = flatTours.filter((dest) => dest.destination.name === "Asia");

	const asia = [
		{
			name: "Albania",
			to: "/destinations/albania-bike-tours/",
		},
		{
			name: "Austria",
			to: "/destinations/austria-bike-tours/",
		},
		{
			name: "Bhutan",
			to: "/destinations/bhutan-cycling-tours/",
		},
		{
			name: "Cambodia",
			to: "/destinations/cambodia-bike-tours/",
		},
		{
			name: "Chile",
			to: "/destinations/chile-bike-tours/",
		},
		{
			name: "Croatia",
			to: "/destinations/croatia-bike-tours/",
		},
		{
			name: "Czech Republic",
			to: "/destinations/czech-republic-bike-tours/",
		},
		{
			name: "France",
			to: "/destinations/france-bike-tours/",
		},
		{
			name: "Greece",
			to: "/destinations/greece-bike-tours/",
		},
		{
			name: "Hungary",
			to: "/destinations/hungary-bike-tours/",
		},
		{
			name: "India",
			to: "/destinations/india-bicycle-tours/",
		},
		{
			name: "Italy",
			to: "/destinations/italy-bike-tours/",
		},
		{
			name: "Jordan",
			to: "/destinations/jordan-bike-tours/",
		},
		{
			name: "Japan",
			to: "/destinations/japan-bike-tours/",
		},
		{
			name: "Laos",
			to: "/destinations/laos-bike-tours/",
		},
		{
			name: "Mongolia",
			to: "/destinations/mongolia-bike-tours/",
		},
		{
			name: "Montenegro",
			to: "/destinations/montenegro-bike-tours/",
		},
		{
			name: "Morocco",
			to: "/destinations/morocco-bike-tours/",
		},
		{
			name: "Nepal",
			to: "/destinations/nepal-bike-tours/",
		},
		{
			name: "Norway",
			to: "/destinations/norway-bike-tours/",
		},
		{
			name: "Philippines",
			to: "/destinations/philippines-bike-tours/",
		},
		{
			name: "Poland",
			to: "/destinations/poland-bike-tours/",
		},
		{
			name: "Portugal",
			to: "/destinations/portugal-bike-tours/",
		},
		{
			name: "Romania",
			to: "/destinations/romania-bike-tours/",
		},
		{
			name: "Slovakia",
			to: "/destinations/slovakia-bike-tours/",
		},
		{
			name: "Slovenia",
			to: "/destinations/slovenia-bike-tours/",
		},
		{
			name: "Sri Lanka",
			to: "/destinations/sri-lanka-cycling-tours/",
		},
		{
			name: "Spain",
			to: "/destinations/spain-bike-tours/",
		},
		{
			name: "Taiwan",
			to: "/destinations/taiwan-bike-tours/",
		},
		{
			name: "Thailand",
			to: "/destinations/thailand-bike-tours/",
		},
		{
			name: "Vietnam",
			to: "/destinations/vietnam-bike-tours/",
		},
	];
	// const india = [
	// 	{
	// 		name: "Rajasthan",
	// 		to: "/destinations/rajasthan-cycling-tours/",
	// 	},
	// 	{
	// 		name: "Kerala & South India",
	// 		to: "/destinations/kerala-cycling-tours/",
	// 	},
	// 	{
	// 		name: "Goa",
	// 		to: "/destinations/goa-cycling-tours/",
	// 	},
	// 	{
	// 		name: "Himalayas",
	// 		to: "/destinations/cycling-tours-himalayas/",
	// 	},
	// 	{
	// 		name: "Northeast",
	// 		to: "/destinations/northeast-india/",
	// 	},
	// ];

	//const seo = { title: "Categories" }
	return (
		<Layout>
			{/* <SEO seo={seo} /> */}
			<GatsbySeo
				title="International Luxury Bike Tours and E-Bike Tours"
				description="Art of Bicycle Trips specializes in crafting distinctive cycling and e-bike adventures to some of the world's most breathtaking destinations."
				// languageAlternates={[
				// 	{
				// 		hrefLang: "en-US",
				// 		href: "https://www.artofbicycletrips.com",
				// 	},
				// 	{
				// 		hrefLang: "en",
				// 		href: "https://www.artofbicycletrips.com",
				// 	},
				// 	{
				// 		hrefLang: "en-IN",
				// 		href: "https://www.artofbicycletrips.in",
				// 	},
				// 	{
				// 		hrefLang: "x-default",
				// 		href: "https://www.artofbicycletrips.com",
				// 	},
				// ]}
			/>

			<LogoJsonLd
				logo="https://www.artofbicycletrips.com/static/Art_of_bicycle_trips_Final_logo2-06a88ab3f31618d868863a9d87f7e6ce.png"
				url="https://www.artofbicycletrips.com/"
			/>
			<Hero />

			{/* <section className="mx-4 my-16 lg:my-28 lg:mx-24">
				<h2 className="text-center font-extrabold mb-10 text-xl sm:text-3xl lg:text-4xl">
					Sharing the joy of bike and travel
				</h2>
				<div className="flex">
					<div className="w-20 h-20">
						<img src={open} alt="open quote" />
					</div>
					<div>
						<p className="prose sm:prose-xl lg:prose-2xl px-2 md:px-4">
							Founded in 2010 during a 100km bumpy ride in India, Art of Bicycle Trips
							specializes in crafting unique cycling and e-bike adventures to India and
							Asia-at-Large. We’re all about small details.
						</p>
					</div>
					<div className="w-20 h-20">
						<img src={close} alt="close quote" />
					</div>
				</div>
			</section> */}

			{/* activities section */}
			{/* <section className="">
				<h2 className="mb-4">Ease back into an adventure</h2>
				<div className="grid grid-cols-1 md:grid-cols-2 gap-20">
					<Card>
						<Link to="/activities/cycling">
							<div className="">
								<StaticImage
									className="rounded-t-lg shadow-lg transform"
									src="../images/rajasthan-bike-tour-1.jpg"
									alt="cycling adventures"
									layout="FULL_WIDTH"
									aspectRatio="1.8"
								/>
							</div>
							<p className="px-4 py-4 font-medium text-xl hover:text-primary">
								Cycling Adventures
							</p>
							<p className="px-4 pb-4 prose prose-sm">
								Led by our top cycling guides, the folks who steer you towards unique
								and enriching experience, you get to ride from one place to another
								through our immaculately planned backroads with support van always near
								at hand.
							</p>
						</Link>
					</Card>
					<Card>
						<Link to="/specialities/family">
							<div className="">
								<StaticImage
									className="rounded-t-lg shadow-lg transform"
									src="../images/kerala-bike-tour-4.jpeg"
									alt="family adventures"
									layout="FULL_WIDTH"
									aspectRatio="1.8"
								/>
							</div>
							<p className="px-4 py-4 font-medium text-xl hover:text-primary">
								Family Adventures
							</p>
							<p className="px-4 pb-4 prose prose-sm">
								Our family adventures are designed to create ever lasting memories and
								offers perfect bonding opportunity in the outdoors with your kids (9-12
								years) and teens (13-16 years), though that doesn’t mean children
								outside that age bracket won’t be welcome!
							</p>
						</Link>
					</Card>
				</div>
			</section> */}

			{/* trustpilot */}
			{/* <div className="text-center mt-24">
				<a
					href="https://www.trustpilot.com/review/artofbicycletrips.com"
					target="_blank"
					rel="noopener noreferrer"
					className="border border-gray-300 p-4 inline-block mx-auto"
				>
					<div className="text-center sm:flex sm:justify-center">
						<div className="flex flex-row justify-center items-center">
							<p className="text-base sm:text-lg font-semibold">Excellent</p>
							<img
								src={stars}
								alt="Trustpilot Logo"
								className="w-24 h-auto sm:w-28 sm:h-auto ml-4 inline-block"
							/>
						</div>
						<div className="flex flex-row mt-1 sm:mt-0 justify-center items-center">
							<p className="text-sm sm:text-sm font-normal sm:ml-3">4.6 out of 5</p>
							<img
								src={tustbadge}
								alt="Trustpilot Logo"
								className="w-auto h-6 sm:w-auto sm:h-6 ml-2 inline-block"
							/>
						</div>
					</div>
				</a>
			</div> */}
			{/* Asia section  */}
			<section className="my-32">
				{/* number speaks more */}
				<div className="bg-secondary rounded-2xl py-20 px-10">
					<div className="mx-auto max-w-7xl px-6 lg:px-8">
						<dl className="grid grid-cols-1 gap-x-8 gap-y-16 text-center lg:grid-cols-3">
							{stats.map((stat) => (
								<div key={stat.id} className="mx-auto flex max-w-xs flex-col gap-y-4">
									<dt className=" leading-7 font-medium lg:text-lg">{stat.name}</dt>
									<dd className="order-first text-3xl font-semibold tracking-tight text-primary sm:text-6xl">
										{stat.value}
									</dd>
								</div>
							))}
						</dl>
					</div>
				</div>

				<div className="flex flex-col md:flex-row sm:justify-between mt-32 mb-4">
					<div>
						<h2 className="mb-4">Our Best-Selling Bike Tours</h2>
						{/* <p className="mt-2 mb-4 md:max-w-md lg:max-w-2xl">
							Choose from 100+ thoughtfully crafted bike tours, designed for those who
							love to stay active and curious while traveling.
						</p> */}
					</div>
					<div>
						<Link to="/tours" className="my-4 sm:my-0">
							<span className="px-5 py-2 border border-primary text-white font-medium leading-relaxed bg-primary rounded-full">
								View all tours
							</span>
						</Link>
					</div>
				</div>
				{/* Region  List Start */}
				<div className="flex flex-row space-x-2 overflow-x-auto whitespace-nowrap capitalize pt-10 pb-16">
					{asia.map((reg) => {
						return (
							<Link key={reg.name} to={`${reg.to}`}>
								<div className="">
									<span className="border-2 border-primary font-medium p-4 md:p-6 rounded-lg">
										{reg.name}
									</span>
								</div>
							</Link>
						);
					})}
				</div>
				{/* Region List End */}

				{/* <TourList tours={flatTours} /> */}
				<TourCarousel tours={first10Tours} />
			</section>

			{/* List of Luxury Tours */}
			{/* <section className="my-32">
				<div className="flex flex-col md:flex-row sm:justify-between mt-24 mb-4">
					<div>
						<h2 className="">Featured Luxury Bike Tours</h2>
						<p className="mt-2 mb-4 md:max-w-md lg:max-w-2xl">
							Offer an unparalleled experience, seamlessly blending leisurely cycling
							holidays with exquisite accommodations at the region's most renowned and
							luxurious hotels.
						</p>
					</div>
					<div>
						<Link
							to="/specialities/luxury-bike-tours/?utm_source=home&utm_medium=destination-link&utm_campaign=view-all"
							className="my-4 sm:my-0"
						>
							<span className="px-5 py-2 text-sm border border-primary text-primary bg-white rounded-full">
								View All
							</span>
						</Link>
					</div>
				</div>
				<div className="mt-10 md:mt-0">
					<TourCarousel tours={first10LuxuryTours} />
				</div>
			</section> */}

			{/* List of Multisport Tours */}
			{/* <section className="my-32">
				<div className="flex flex-col md:flex-row sm:justify-between mt-24 mb-4">
					<div>
						<h2 className="">Featured Multi-sport Tours</h2>
						<p className="mt-2 mb-4 md:max-w-md lg:max-w-2xl">
							Designed for those who want to fully embrace a region's wonders by
							combining thrilling biking experiences with an array of complementary
							activities.
						</p>
					</div>
					<div>
						<Link
							to="/specialities/multi-sport-tours/?utm_source=home&utm_medium=multi-link&utm_campaign=view-all"
							className="my-4 sm:my-0"
						>
							<span className="px-5 py-2  text-sm border border-primary text-primary bg-white rounded-full">
								View All
							</span>
						</Link>
					</div>
				</div>
				<div className="mt-10 md:mt-0">
					<TourCarousel tours={first10MultiTours} />
				</div>
			</section> */}

			{/* List of Road Tours */}
			{/* <section className="my-32">
				<div className="flex flex-col md:flex-row sm:justify-between mt-24 mb-4">
					<div>
						<h2 className="">Featured Road Bike Tours</h2>
						<p className="mt-2 mb-4 md:max-w-md lg:max-w-2xl">
							Tailored for avid cyclists who relish the opportunity to explore nature
							on their road bikes and conquer century rides.
						</p>
					</div>
					<div>
						<Link
							to="/specialities/road-bike-tours/?utm_source=home&utm_medium=road-link&utm_campaign=view-all"
							className="my-4 sm:my-0"
						>
							<span className="px-5 py-2  text-sm border border-primary text-primary bg-white rounded-full">
								View All
							</span>
						</Link>
					</div>
				</div>
				<div className="mt-10 md:mt-0">
					<TourCarousel tours={first10RoadTours} />
				</div>
			</section> */}

			{/* List of Family Tours */}
			{/* <section className="my-32">
				<div className="flex flex-col md:flex-row sm:justify-between mt-24 mb-4">
					<div>
						<h2 className="">Featured Family Bike Tours</h2>
						<p className="mt-2 mb-4 md:max-w-md lg:max-w-2xl">
							Designed with the whole family in mind, our tours offer the perfect blend
							of exciting biking excursions and family-friendly activities.
						</p>
					</div>
					<div>
						<Link
							to="/specialities/family/?utm_source=home&utm_medium=family-link&utm_campaign=view-all"
							className="my-4 sm:my-0"
						>
							<span className="px-5 py-2  text-sm border border-primary text-primary bg-white rounded-full">
								View All
							</span>
						</Link>
					</div>
				</div>
				<div className="mt-10 md:mt-0">
					<TourCarousel tours={first10FamilyTours} />
				</div>
			</section> */}

			{/* India section */}
			{/* <section className="mb-12">
				<div className="flex flex-col sm:flex-row sm:justify-between mt-24 mb-4">
					<h2 className="">Most popular cycling tours in India</h2>
					<Link to="/destinations/india" className="my-4 sm:my-0">
						<span className="px-5 py-2   text-sm border border-primary text-primary bg-white rounded-full">
							View All
						</span>
					</Link>
				</div>
				
				<div className="flex flex-row space-x-2 overflow-x-auto whitespace-nowrap capitalize pt-8 pb-12">
					{india.map((reg) => {
						return (
							<Link key={reg.name} to={reg.to}>
								<div className="">
									<span className="border border-primary p-4 md:p-6 rounded-lg">
										{reg.name}
									</span>
								</div>
							</Link>
						);
					})}
				</div>
				
				<TourCarousel tours={indiaTours} />
			</section> */}

			{/* private section */}
			<section className="my-32">
				<div className="flex flex-col lg:items-center lg:flex-row bg-secondary w-auto h-auto p-10 lg:p-20 rounded-lg my-20">
					<div className="lg:w-1/2">
						<StaticImage
							className="rounded-lg shadow-lg transform"
							src="../images/sikkim-bike-tour-22.jpeg"
							alt="private adventures"
							layout="FULL_WIDTH"
							aspectRatio="1.5"
							loading="lazy"
						/>
					</div>
					<div className="mt-4 lg:w-1/2 lg:pl-20">
						<h3 className=" mb-1 hover:text-primary">Craft a Private Bike Tour</h3>
						<p className="text-primary text-sm mb-6">
							Ideal for family & friends, local clubs
						</p>
						<p className="mb-4 prose">
							We provide private departures for nearly all our trips. Groups of four or
							more can enjoy them at the same price as our regular group trips.
						</p>
						{/* <p className="mb-4 prose">
							Can't find a standard itinerary you like? Design your own private
							adventure or customize an existing one!
						</p> */}
						{/* <p className="prose">
							We offer private departures on almost all our itineraries, and for groups
							of four or more, they cost the same as our standard trips! If you don’t
							find a suitable standard itinerary then you can always design your own
							private adventure or customize an existing itinerary.
						</p> */}
						<div className="my-4 mt-8">
							<Link
								to="/private?utm_source=home&utm_medium=private-section&utm_campaign=go-private"
								className="items-center justify-center px-8 py-3 border border-transparent text-base font-medium rounded-full text-white bg-primary hover:bg-primary md:py-4 md:px-10"
							>
								Go private
							</Link>
						</div>
					</div>
				</div>
			</section>

			{/* testimonials */}
			<section className="my-16 lg:my-28">
				<div>
					<div className=" 2xl:mx-auto 2xl:container">
						<h2 className="mb-16">What our guests are saying</h2>
						{/* <h2 className=" font-semibold xl:block hidden leading-tight text-gray-800">
							What our customers are saying
						</h2>
						<h2 className=" font-semibold xl:hidden block leading-tight lg:leading-10 text-gray-800">
							What our customers are saying
						</h2> */}
						{/* <div className="mt-4">
							<a
								href="https://www.trustpilot.com/review/artofbicycletrips.com"
								target="_blank"
							>
								
								<div className="mb-2">
									<StaticImage
										className=""
										src="../images/badge.png"
										alt=""
										width={250}
										aspectRatio="2.21"
										loading="lazy"
									/>
								</div>
								<div className="w-64">
									<p className="bg-bgtrust px-2 py-1  uppercase rounded text-trust text-xs font-medium">
										Rated Excellent. Based on 90 reviews
									</p>
								</div>
							</a>
						</div> */}

						{/* Reviews */}

						<ReviewsCarousel />

						{/* <TrustpilotReviews /> */}

						{/* <div className="flex">
							<div key={review.id} className="mt-14 lg:flex">
								<div className="relative lg:w-1/2 ">
									<StaticImage
										className="rounded-lg shadow-lg transform"
										src="../images/thailand-bike-tour-4.jpeg"
										alt="cycling adventures"
										layout="FULL_WIDTH"
										aspectRatio="1.3"
										loading="lazy"
									/>

									<div className="w-32 lg:flex hidden items-center justify-center absolute top-0 -mr-16 -mt-14 right-0 h-32 bg-bgtrust rounded-full">
										<svg
											width="24"
											height="24"
											viewbox="0 0 24 24"
											fill="none"
											xmlns="http://www.w3.org/2000/svg"
										>
											<path
												d="M9.983 3v7.391c0 5.704-3.731 9.57-8.983 10.609l-.995-2.151c2.432-.917 3.995-3.638 3.995-5.849h-4v-10h9.983zm14.017 0v7.391c0 5.704-3.748 9.571-9 10.609l-.996-2.151c2.433-.917 3.996-3.638 3.996-5.849h-3.983v-10h9.983z"
												fill="#125960"
											></path>
										</svg>
									</div>
								</div>
								<div className="lg:w-1/2 xl:ml-32 lg:ml-20 lg:mt-0 mt-4 flex flex-col justify-between">
									<div>
										<h3 className="font-medium xl:leading-loose text-gray-800">
											We loved the trip!
										</h3>
										<p className="leading-6 mt-4 font-light text-gray-600">
											We had a private tour for 4, premium hotels biking through Vietnam
											and Cambodia. Hotels were excellent (loved the two Raffles); we had
											some food restrictions and guides were meticulous in ensuring our
											dietary needs were satisfied; the meals were good, and countryside
											beautiful. A highlight was the "homestay" in Cambodia...the family
											was delightful. Be sure to send your height in advance of arrival so
											they have the right size bike for you....the bikes were in great
											condition and the drivers carried sufficient equipment to make any
											necessary repairs along the way. This was our second trip with Art of
											Bicycle,  and we'll return again.
										</p>
									</div>
									<div className="lg:mt-4 mt-8">
										<p className="font-medium leading-4 text-gray-800">
											Mary and Martha, US
										</p>
									</div>
								</div>
							</div>
						</div> */}
					</div>
				</div>
			</section>
			{/* Blog */}
			<section className="my-16 lg:my-28">
				{allArticles.length > 0 && (
					<div className="">
						<div className="flex flex-col sm:flex-row sm:justify-between mt-24 mb-10">
							<h2 className="">Plan with our comprehensive guides</h2>
							<Link to="/blog" className="my-4 sm:my-0">
								<span className="px-5 py-2   text-sm border border-primary text-primary bg-white rounded-full">
									View All
								</span>
							</Link>
						</div>
						<ArticleCarousel articles={first10Articles} />
					</div>
				)}
			</section>

			{/* instagramd feed */}
		</Layout>
	);
};

// queries

// allStrapiSpeciality {
// 	edges {
// 		node {
// 			name
// 			desc
// 			slug
// 			tours {
// 				id
// 				title
// 				popular
// 				price
// 				duration
// 				tourType
// 				slug
// 				featuredImage {
// 					localFile {
// 						publicURL
// 						childImageSharp {
// 							gatsbyImageData(
// 								layout: FULL_WIDTH
// 								placeholder: BLURRED
// 								aspectRatio: 1.3
// 							)
// 						}
// 					}
// 				}
// 				slider {
// 					id
// 					url
// 					name
// 					alternativeText
// 					localFile {
// 						childImageSharp {
// 							gatsbyImageData(aspectRatio: 1.3)
// 						}
// 					}
// 				}
// 				quickfacts {
// 					lodging
// 					level
// 				}
// 			}
// 		}
// 	}
// }

// allStrapiArticle(sort: { fields: updated_at, order: DESC }) {
// 	edges {
// 		node {
// 			strapiId
// 			slug
// 			title
// 			updated_at
// 			category {
// 				name
// 			}
// 			image {
// 				localFile {
// 					childImageSharp {
// 						gatsbyImageData(
// 							layout: FULL_WIDTH
// 							placeholder: BLURRED
// 							aspectRatio: 1.3
// 						)
// 					}
// 				}
// 			}
// 			author {
// 				name
// 				image {
// 					localFile {
// 						childImageSharp {
// 							gatsbyImageData(width: 30, height: 30)
// 						}
// 					}
// 				}
// 			}
// 		}
// 	}
// }

// in allStrapiTour query

// slider {
// 	id
// 	url
// 	name
// 	alternativeText
// 	localFile {
// 		childImageSharp {
// 			gatsbyImageData(aspectRatio: 1.3)
// 		}
// 	}
// }

export const query = graphql`
	query ActivitiesQuery {
		allStrapiTour(
			sort: { fields: price, order: DESC }
			filter: { popular: { eq: true } }
		) {
			edges {
				node {
					title
					popular
					price
					duration
					slug
					featuredImage {
						localFile {
							publicURL
							childImageSharp {
								gatsbyImageData(
									layout: FULL_WIDTH
									placeholder: BLURRED
									aspectRatio: 1.3
								)
							}
						}
					}

					quickfacts {
						lodging
						level
					}
				}
			}
		}
		allStrapiArticle(sort: { fields: updated_at, order: DESC }) {
			edges {
				node {
					strapiId
					slug
					title
					updated_at
					category {
						name
					}
					image {
						localFile {
							childImageSharp {
								gatsbyImageData(
									layout: FULL_WIDTH
									placeholder: BLURRED
									aspectRatio: 1.3
								)
							}
						}
					}
					author {
						name
						image {
							localFile {
								childImageSharp {
									gatsbyImageData(width: 30, height: 30)
								}
							}
						}
					}
				}
			}
		}
	}
`;

export default IndexPage;
